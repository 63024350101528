html,

body {
    height: 100%;
    font-family: AmericanSans;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    cursor: default;
    text-align:justify;
}

@media(max-width:768px) {
    .main {
        text-align: center;
        margin: 10% auto 0;
    }
}

.main {
    margin: 0 auto;
}

